<template>
    <v-main>
        <template v-slot:default>
            <v-section-heading>
                <template v-slot:default>
                    Roles
                </template>

                <template v-slot:description>
                    A list of all the roles in the application.
                </template>
            </v-section-heading>

            <v-role-table :query="filters" />
        </template>
    </v-main>
</template>

<script>
import RoleTable from "@/components/application/role/role-table.vue";

export default {
    components: {
        "v-role-table": RoleTable,
    },
    data() {
        return {
            /**
             * @todo Write a description.
             */
            filters: {},
        };
    },
};
</script>
