<template>
    <v-model-table module="roleModule" :columns="columns" :query="query">
        <template v-slot:column_name="{ row }">
            <v-link :to="{ name: 'roles.show', params: { role: row.role_id } }">
                {{ row.name }}
            </v-link>
        </template>
    </v-model-table>
</template>

<script>
export default {
    name: "RoleTable",
    props: {
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    computed: {
        columns() {
            const tableColumns = {
                name: {
                    label: "Name",
                },
                maximum_incomplete_orders: {
                    label: "Maximum Incomplete Orders",
                    align: "right",
                    formatter: (row) => row.maximum_incomplete_orders || 0,
                },
                users_count: {
                    label: "# of Users",
                    align: "right",
                    formatter: (row) => row.users_count || 0,
                },
            };

            return tableColumns;
        },
    },
};
</script>
